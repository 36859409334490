export default {
  resources: {
    'apps/templates': {
      name: 'Templates',
      lastUpdatedAt: 'Última atualização em: %{date}',
    },
    dashboard: {
      activity: 'Atividades',
      dashboard: 'Painel de Controle',
      messages: 'Mensagens',
      users: 'Usuários',
      mobileUsers: 'Usuários Mobile',
      clients: 'Clientes',
      client: 'Cliente',
      apps: 'Apps',
      channels: 'Canais',
      alerts: 'Alertas',
      mobileProfiles: 'Perfis Mobile',
      top3Apps: 'Top 3 Aplicativos',
      top3SitesTeams: 'Top 3 Locais/Times',
      top3AppsDescription: 'Aplicativos com mais perfis mobile registrados em ',
      top3SitesTeamsDescription: 'Locais/Times com mais perfis mobile registrados em ',
      emptyMessage: 'Não há informação suficiente.',
      app: 'Aplicativo',
      general: 'Geral',
      siteAndTeam: 'Local/Time',
      mobileProfileOfThisSite: 'Perfis Mobile desse Local/Time',
    },
    projects: {
      name: 'Nome',
      description: 'Descrição',
      default: 'Projeto padrão',
      defaultDescription: 'Projeto padrão do cliente',
      allProjects: 'Todos os projetos',
      table: {
        noDataMessage: 'Ainda não existem projetos criados para este cliente.',
      },
      createModal: {
        title: 'Criar projeto para "%{clientName}"',
      },
      editModal: {
        title: 'Editar projeto "%{name}"',
      },
      deleteModal: {
        title: 'Deletar projeto "%{name}"?',
      },
      fields: {
        namePlaceholder: 'Nome do projeto',
        descriptionPlaceholder: 'Descrição do projeto',
      },
      buttons: {
        showInactive: 'Mostrar Inativo',
        create: 'Novo Projeto',
        active: 'Ativo',
        save: 'Salvar',
        cancel: 'Cancelar',
        yes: 'Sim',
        no: 'Não',
        createApp: 'Novo App',
        export: 'Exportar',
      },
    },
    summary: {
      nameClient: 'Nome do Cliente',
      nicknameClient: 'Apelido',
      versionClient: 'Versão do App',
      advice: 'Cheque abaixo as informações principais do cliente.',
    },
    'public-codes': {
      name: 'Código Público |||| Códigos Públicos',
      fields: {
        name: 'Nome',
        code: 'Codigo',
        description: 'Description',
      },
    },
    'registration-data/schemas': {
      name: 'Dados cadastrais |||| Dados cadastrais',
    },
    terms: {
      name: 'Termo |||| Termos',
      fields: {
        title: 'Título',
        content: 'Conteúdo',
        publishedAt: 'Data de Publicação',
        active: 'Ativo',
      },
    },
    documents: {
      name: 'Documento |||| Documentos',
      fields: {
        name: 'Nome',
        content: 'Conteúdo',
        publishedAt: 'Data de Publicação',
        active: 'Ativo',
      },
    },
    'broadcast-messages': {
      name: 'Mensagem de notícias |||| Mensagens de notícias',
      fields: {
        createdAt: 'Enviada',
        text: 'Texto',
        'Channel.name': 'Canal',
        'MonitoringProvider.name': 'Monitoramento',
      },
    },
    'alert-assignments': {
      name: 'Status |||| Statuses',
      fields: {
        createdAt: 'Recebido',
        status: 'Status',
        MonitoringProviderId: 'Monitoramento',
      },
    },
    'registration-forms': {
      name: 'Forma de registro |||| Formas de registro',
      fields: {
        name: 'Nome',
        'schema.title': 'Título',
        'schema.fields': 'Fields',
      },
    },
    channels: {
      name: 'Canal |||| Canais',
      fields: {
        createChannel: 'Criar Canal',
        name: 'Nome do Canal',
        namePlaceholder: 'Insira o nome do canal',
        channelType: 'Tipo de Canal',
        MonitoringProviderIds: 'Órgão de Monitoramento',
        pastingChannel: 'Colar',

        primary: 'Canal Primário',
        config: {
          about: 'Sobre o canal',
          updated: 'Última atualização:',
          // titlePage: 'Título na página',
          finallyAlert: 'Finalização de alerta',
          commentary: 'Finalizar com comentário do usuário:',
          noCommentary: 'Finalizar sem comentário do usuário:',
          helpertext: 'Em breve',
          settings: 'Configurações e permissões',
          siteManagerOnly: 'Visível somente para gerente',
          allowGps: 'Permitir acesso ao GPS',
          allowGeocoding: 'Geocodificação GPS',
          anonymous: 'Canal Anônimo',
          notifyManager: 'Notificar o Gerente',
          notifySucess: 'Salvo com Sucesso',
          textColor: 'Cor do Texto',
          bgColor1: 'Cor de Fundo 1',
          bgColor2: 'Cor de Fundo 2 ',
          icon: 'Ícone',
          channelTitle: 'Título do Canal',
          sendNowTitle: 'Título do Botão:',
          sendNowText: 'Mensagem do Sistema:',
          sendOtherTitle: 'Envie Outro Título',
          disclaimer: 'Aviso Legal',
          notifySurveyResult: 'Criar notificação de resultado',
          notifyOpened: 'Notificar quando canal for acessado',
          useWebclientAuth: 'Autenticar com SASI',
          useWebclientAuthRefresh: 'Renovar token',
          // usePassport: 'Use token de passaporte',
          url: 'URL',
          androidSdkKey: 'Android SDK Key',
          iosSdkKey: 'IOS SDK Key',
          applicationId: 'Application ID',
          'schema.fields': 'Custom fields',
          requireConfirm: 'Aprovação necessária',
          dataChannel: 'Coleta de Dados',
          visibilityQuery: 'Consulta de Visibilidade',
          parentId: 'Canal Pai',
          channelId: 'ID do Canal',
          channelType: 'Tipo do Canal',
          newApi: 'Utilizar a nova API v2',
          messageType: 'Tipo de Mensagem',
          primary: 'Tornar canal primário',
          main: 'Tornar aplicativo principal',
          isEmergency: 'Número de telefone de emergência',
          faceRecognitionType: 'Type',
          faceRecognitionForm: 'Form',
          faceRecognitionCamera: 'Câmera',
        },
        placeholders: {
          allowGps: 'Permite que a localização do usuário seja enviada junto ao alerta.',
          anonymous: 'Esta opção permite o envio de alertas anonimamente.',
          primary: 'Este canal será visível na página principal do app.',
          siteManagerOnly: 'Este canal será visível somente para o gerente do cliente.',
          notifyManager: 'O gerente será notificado de cada alerta enviado.',
          notifyOpened: 'O gerente será notificado quando o canal for acessado.',
          sasiToken:
            'Permite que sites externos acessem informações de usuários, e execute ações limitadas com seus usernames através do SASI. Expira a cada hora.',
          insertTag: 'Insira uma TAG',
          bubbleUrl: 'Insira a URL',
          requireConfirm:
            'Uma notícia só será enviada após o conteúdo ser revisado por um super usuário ou um operador de notícias.',
          newApi: 'Essa opção é temporária. No futuro, essa opção será padrão para todos os canais Bubble.',
          buttons: 'Insira um título',
          example: '(Ex.: Finalizado)',
          useWebclientAuthRefresh: 'Permite que tokens sejam renovados sem a necessidade de abrir a URL novamente.',
          main: 'Compartilhe suas configurações com os próximos aplicativos criados para o mesmo cliente.',
          telephone: 'Insira o número de telefone',
          pasteChannel: 'Insira o nome do canal',
          isEmergency: 'Remove a formatação internacional do número de telefone.',
        },
      },
      schema: {
        general: 'Geral',
        comments: 'Comentários',
        schemaTitle: 'Título na página',
        validation: 'Validação',
        pageBreak: 'Quebra de página',
        columns: 'Colunas',
        min: 'Min',
        max: 'Max',
        step: 'Degrau',
        icon: 'Ícone',
        visibleIfQuery: 'Condições',
        requiredErrorText: 'Texto de erro obrigatório',
        invalidErrorText: 'Valor inválido',
        maxLength: 'Valor muito grande. Máx: %{max}',
        nonUniqueNameErrorText: 'O campo "nome" deve ser único',
        schemaTitlePlaceholder: 'Por favor, preencha os campos adicionais',
        schemaTitleHelperText: '  ',
        allowComments: 'Permitir comentários',
        allowAttachments: 'Permitir anexos',
        allowAttachmentCamera: 'Permitir câmera',
        allowAttachmentMic: 'Permitir gravação de áudio',
        allowAttachmentFile: 'Permitir arquivos',
        allowQrCode: 'Permitir QrCodes',
        allowSelectAll: 'Permitir Selecionar Tudo',
        hasOther: 'Tem outro',
        otherText: 'Outro Texto',
        limit: 'Limite',
        name: 'Nome',
        placeholder: 'Placeholder',
        type: 'Tipo',
        defaultValue: 'Valor padrão',
        required: 'Requeridos',
        title: 'Título',
        options: 'Opções',
        format: 'Formato',
        tag: 'TAG',
        validators: {
          title: 'Validadores',
          fields: {
            type: 'Tipo',
            text: 'Texto',
            regex: 'RegExp',
          },
        },
        choices: {
          title: 'Escolhas',
          fastentry: 'Entrada rápida',
          import: 'Importar CSV',
          preview: 'Preview',
          fields: {
            value: 'Valor',
            text: 'Texto',
          },
        },
      },
    },
    alerts: {
      name: 'Alerta |||| Alertas',
      fields: {
        showClosed: 'Exibir Fechados',
        test: 'Teste',
        text: 'Mensagem',
        date: 'Data',
        location: 'Local',
        MobileProfileId: 'MAP',
        status: 'Status',
        Channel: {
          name: 'Canal',
        },
        ClientId: 'Cliente',
        priority: 'Pri.',
      },
    },
    apps: {
      name: 'App |||| Apps',
      noDataMessageApp: 'Ainda não existem apps criados para este cliente.',
      deleteAppWarning: 'Todos os canais associados a este app serão perdidos, tem certeza de que deseja excluir?',
      deleteAppSuccess: 'App deletado com sucesso!',
      settingsTabs: {
        theme: 'Tema',
        channels: 'Canais',
        versions: 'Versões',
      },
      fields: {
        project: 'Projeto',
        description: 'Descrição',
        placeholderDescription: 'Insira uma breve descrição à respeito deste app.',
        titleVersion: 'Publicar nova versão do App',
        versionApp: 'Nome da versão',
        placeholderVersion: '(Opcional) Insira um nome para essa versão. Ex.: v.0.1.0',
        commentVersion: 'Descrição da versão',
        commentaryVersion: 'Insira um comentário nesta versão.',
        placeholderCommentary: 'Comente um pouco sobre o que mudou nessa versão.',
        updatedAt: 'Atualizado em',
        name: 'Nome do App',
        nameApp: 'Qual é o nome do app?',
        placeholderName: 'Insira o nome deste app.',
        placeholderClient: 'Selecione o cliente ao qual esse app vai pertencer.',
        nickname: 'Apelido',
        placeholderNickname: 'Insira o apelido deste app.',
        title: 'Criar App',
        publishApp: 'Publicar App',
        publishedVersion: 'Versão Publicada',
        // publishVersion: 'Despublicar Versão',
        // unpublishedVersion: 'Restaurar Versão',
        // draftVersion: 'Publicar Versão',
        salveTemplate: 'Salvar Template',
        salveasTemplate: 'Salvar como template',
        renameVersion: 'Renomear Versão',
        deleteApp: 'Deletar App',
        Channels: 'Canais',
        isDefault: 'Definir como padrão',
        client: 'Clientes',
        Project: {
          name: 'Projeto',
        },
        status: {
          draft: 'rascunho',
          published: 'publicada',
          unpublished: 'despublicada',
          publishVersion: 'Despublicar Versão',
          unpublishedVersion: 'Restaurar Versão',
          draftVersion: 'Publicar Versão',
        },
        config: {
          properties: 'Propriedades Básicas',
          banner: 'Banner',
          bannerBase: 'Arraste seu banner para fazer o upload ou clique para selecionar a imagem.',
          textColor: 'Cor do texto',
          backgroundColor: 'Cor do plano de fundo',
          background: 'Plano de fundo',
          headerColor: 'Cor do cabeçalho',
          secondaryBackgroundColor: 'Cor de fundo padrão',
          bannerNickname: ' Tornar o apelido visível no banner',
          buttonsBorderRadius: 'Raio da borda',
          secondaryButtonsRatio: 'Escala Secundária',
          primaryButtonsRatio: 'Escala Primária',
          centerPrimary: 'Primary Center',
          centerSecondary: 'Secondary Center',
          primaryIconSize: 'Primary Icon',
          secondaryIconSize: 'Secondary Icon',
          primaryButtonsFontSize: 'Primary Font',
          secondaryButtonsFontSize: 'Secondary Font',
          themeName: 'Theme',
          nickname: 'Apelido',
          name: 'Nome',
          namePlaceholder: 'Insira o nome do App',
          nicknamePlaceholder: 'Insira o apelido do App',
          descriptionPlaceholder: 'Insira a descrição do App',
          projeto: 'Projeto',
          description: 'Descrição',
          general: 'Geral',
          terms: 'Termo',
          default: 'Definir como padrão',
        },
      },
      errors: {
        CANNOT_DELETE_WITH_RELATIONS: 'Existem locais ou times utilizando este app',
        CLIENT_LOCKED: `Não foi possível realizar esta ação porque o cliente está locked!
        (error: %{requestId})`,
      },
    },
    surveys: {
      name: 'Pesquisa |||| Pesquisas',
      fields: {
        name: 'Nome',
        Channels: 'Canaisels',
        active: 'Ativo',
      },
    },
    'survey-results': {
      name: 'Resultados da pesquisa |||| Resultados da pesquisas',
      fields: {
        MobileProfileId: 'MAP',
      },
    },
    'monitoring-providers': {
      name: 'Monitoramento |||| Monitoramento',
      create: 'Novo Monitoramento',
      title: 'Criar Provedor',
      editTitle: 'Editar Provedor',
      nameProvider: 'Qual o nome do provedor?',
      placeholderName: 'Insira o nome deste provedor.',
      placeholderEmail: 'Insira o email do responsável',
      emailProvider: 'Qual o email do responsável pelo provedor?',
      typeProvider: 'Qual o tipo do provedor?',
      noDataMessage: 'Ainda não existem monitoramentos criados para este cliente.',
      fields: {
        name: 'Nome do Monitoramento',
        providerType: 'Tipo',
        callbackUrl: 'URL da API de Retorno',
        notifyEmail: 'E-mail de notificação',
        email: 'Email do responsável pelo provedor',
        active: 'Ativo',
        user: {
          username: 'Nome do Usuário',
          name: 'Nome do Gerente',
          email: 'Email do Gerente',
        },
        config: {
          defaultStatus: {
            'assigned.text': 'Texto Aberto',
            'assigned.icon': 'Ícone Aberto',
            'assigned_primary.text': 'Texto Notificaçõ',
            'assigned_primary.icon': 'Ícone Notificaçõ',
            'closed.text': 'Texto Fechado',
            'closed.icon': 'Ícone Fechado',
            'acknowledged.text': ' Texto Cliente',
            'acknowledged.icon': 'Ícone Cliente',
          },
          status: {
            name: 'Nome do Status',
            icon: 'Ícone do Status',
          },
          notifications: {
            name: 'Nome da Notificação',
          },
        },
      },
    },
    clients: {
      name: 'Cliente |||| Clientes',
      fields: {
        name: 'Nome',
        code: 'Código do Cliente',
        nickname: 'Apelido',
        user: {
          username: 'Nome do Usuário',
          name: 'Nome do gerente',
          email: 'E-mail do gerente',
        },
        config: {
          minAppVersion: 'Min App Version',
        },
      },
      tabs: {
        summary: 'Resumo',
        projects: 'Projetos',
        sites: 'Locais',
        teams: 'Times',
        providers: 'Monitoramento',
        apps: 'Apps',
        users: 'Usuários',
        licenses: 'Comercial',
      },
    },
    sites: {
      name: 'Local |||| Locais',
      createSite: 'Novo local',
      exportSites: 'Exportar locais',
      formatSites: 'Formato do arquivo de exportação',
      inactiveSite: 'Exportar de locais inativos',
      importSites: 'Importar locais',
      downloadExample: 'Baixar exemplo',
      importCSVLabel: 'Arraste seu arquivo CSV para carregá-lo, ou clique para selecionar.',
      noSites: 'Ainda não existem locais criados para este cliente.',
      export: {
        siteName: 'Nome do Local',
        siteAddress: 'Endereço do Local',
        siteZipcode: 'CEP',
        siteCity: 'Cidade',
        siteRadius: 'Raio',
        siteTag: 'Tag',
        siteState: 'Estado',
        siteReferencePoint: 'Ponto de referência',
        siteApp: 'Nome do App',
        siteAppCode: 'Código do App',
        siteCountry: 'País',
        siteLatitude: 'Latitude',
        siteLongitude: 'Longitude',
        siteManagerName: 'Nome do Gerente do Local',
        smTelCountryCode: 'DDI do telefone do gerente',
        smTelAreaCode: 'DDD do telefone do gerente',
        smTelNum: 'Número do telefone do gerente',
        smJobTitle: 'Função do Gerente',
        smEmail: 'Email do Gerente',
      },
      fields: {
        Project: {
          name: 'Projeto',
        },
        name: 'Nome do Local',
        radius: 'Raio de pesquisa',
        app: 'App',
        active: 'Ativos',
        registrationCodes: 'Códigos de registro',
        location: {
          formatted: 'Endereço',
          address: 'Endereço',
          lat: 'Latitude',
          lng: 'Longtitude',
        },
        referencePoint: 'Ponto de Referência',
        user: {
          email: 'E-mail',
          username: 'Nome do Usuário',
        },
        ClientId: 'Cliente',
        appId: 'App',
        panel: {
          profileProps: {
            job: 'Cargo',
            phone: 'Número de telefone',
          },
          name: 'Nome',
          status: 'Status',
        },
      },
    },
    teams: {
      name: 'Time |||| Times',
      create: 'Novo Time',
      noDataMessage: 'Ainda não existem times criados para este cliente.',
      fields: {
        name: 'Nome do Time',
        timezone: 'Zona do Time',
        active: 'Ativo',
        appId: 'App',
        ClientId: 'Cliente',
        panel: {
          profileProps: {
            email: 'E-mail',
            job: 'Cargo',
            phone: 'Número de telefone',
          },
          name: 'Nome',
          status: 'Status',
        },
      },
    },
    maps: {
      name: 'Perfil Móvel |||| Perfis Móveis',
      fields: {
        name: 'Nome',
        profileProps: {
          email: 'E-mail',
          job: 'Cargo',
          phone: 'Telefone',
        },
        active: 'Ativo',
        siteManager: 'Gerente do Local',
        createdAt: 'Criado em',
        ClientId: 'Cliente',
        SiteId: 'Local/Time',
        appVersion: 'Versão',
      },
    },
    'mobile-users': {
      name: 'Usuário móvel |||| Usuários móveis',
      fields: {
        name: 'Nome',
        phone: 'Telefone',
        email: 'E-mail',
        active: 'Ativo',
      },
    },
    users: {
      titleUser: 'Criar novo Usuário',
      titleEdit: 'Editar Usuário',
      emailUser: 'E-mail',
      create: 'Novo Usuário',
      nameUser: 'Nome',
      usernameUser: 'Nome de Usuário',
      password: 'Senha',
      passwordConfirm: 'Confirmar senha',
      user: 'Usuário',
      office: 'Cargo',
      localteam: 'Local/Time',
      passwordUpdated: 'Senha atualizada com sucesso! Agora é só entrar!',
      name: 'Usuário |||| Usuários',
      phone: 'telefone',
      email: 'email',
      placeholderName: 'Nome completo do usuário',
      placeholderUsername: 'Insira o nome de identificação do usuário',
      placeholderEmail: 'E-mail do usuário',
      placeholderPassword: 'Senha do usuário',
      placeholderConfirmPassword: 'Confirme sua senha',
      errors: {
        fields: {
          required: 'Nome não pode ficar em branco',
          passwordsDontMatch: 'As senhas não são iguais',
        },
        external: {
          INVALID_OLD_PASSWORD: 'Senha atual inválida.',
          INTERNAL_ERROR: 'Algo deu errado, por favor tente novamente mais tarde.',
          INVALID_PASSWORD: 'Senha inválida.',
          MISSING_TOKEN: 'Usuário não autorizado.',
          VERIFICATION_INVALID_CODE: 'Código inválido.',
        },
      },
      fields: {
        name: 'Nome',
        userType: 'Type',
        username: 'Nome do Usuário',
        email: 'E-mail',
        active: 'Ativo',
        phone: 'Telefone com código de área (Ex.: +5592999999999)',
        mfaMethod: 'Método MFA',
        Client: {
          name: 'Cliente',
        },
        ClientId: 'Cliente',
        SiteId: 'Locais',
        MonitoringProviderId: 'Órgão de Monitoramento',
        id: 'Id',
        role: 'Perfil',
        password: 'Senha',
        passwordConfirm: 'Confirmação da senha',
        token: 'API token',
        verify: 'Verificar',
      },
    },
    'license-comments/sasi': {
      commentTitle: 'Criar novo comentário comercial para o cliente',
      noCommercialMessage: 'Ainda não existem comentários comerciais criados para este cliente.',
      name: 'Comercial |||| Comercial',
      create: 'Novo Comercial',
      createTitle: 'Novo termo comercial',
      editTitle: 'Atualizar termo comercial',
      client: 'Cliente',
      phases: {
        pilot: 'Piloto',
        contract: 'Contrato',
        price_proposal: 'Proposta de Preço',
        implementation: 'Implementação',
        other: 'Outro',
      },
      fields: {
        Project: {
          name: 'Projeto',
        },
        title: 'Título',
        titlePlaceholder: 'Título deste comentário.',
        startDate: 'Data de início do acordo',
        endDate: 'Data de término do acordo',
        responsible: 'Responsável',
        responsiblePlaceholder: 'Nome do responsável por este documento.',
        lastUpdatedBy: 'Última atualização por',
        comment: 'Comentário',
        createdAt: 'Criado em',
        updatedAt: 'Atualizado em',
        active: 'Ativo',
        phase: 'Fase',
        phaseComment: 'Comentário da fase',
      },
    },
    profiles: {
      title: 'Informações pessoais',
      add: 'Adicionar',
      update: 'Atualizar',
      config: 'Configurar',
      multiFactorAuth: 'Autenticação Multifatores',
      fields: {
        name: 'Nome',
        user: 'Usuário',
        password: 'Senha',
        email: 'E-mail',
        emailNotFound: 'Você ainda não adicionou um e-mail.',
        phone: 'Telefone',
        phoneNotFound: 'Você ainda não adicionou um número.',
      },
    },
  },
  actionButtons: {
    edit: 'Editar',
    saveChanges: 'Salvar alterações',
    delete: 'Deletar',
    save: 'Salvar',
    cancel: 'Cancelar',
    yes: 'Sim',
    no: 'Não',
    create: 'Criar',
    define: 'Definir como padrão',
  },
  appTitle: 'SASI Admin',
  consts: {
    days: '%{smart_count} day |||| %{smart_count} days',
  },
  locales: {
    'en-US': 'Inglês',
    'pt-BR': 'Português',
  },
  fields: {
    title: 'Title',
    icon: 'Ícone',
    nameIcon: 'Nome',
    actionsIcon: 'Ações',
    publishedAt: 'Data de Publicação',
    contentId: 'Content ID',
    autoApprove: 'Aprovação automática',
    form: 'Formulário',
    handler: 'Manipulador de registro',
    recieveTestNotifications: 'Receba notificações de teste',
    intercomMode: 'Intercom Mode',
    date: 'Data',
    messageType: 'Tipo de Mensagem',
    rpi: 'RPI',
    status: 'Status',
    client: 'Cliente',
    site: 'Local',
    siteTeam: 'Local/Time',
    test: 'Teste',
    app: 'App',
    name: 'Nome',
    active: 'Ativo',
    channel: 'Canal',
    location: 'Local',
    alertDate: 'Data/Tempo',
    notification: 'Notificaçõ',
    contact: 'Comunicante',
    map: 'Map',
    referencePoint: 'Ponto de Referência',
    customStatuses: 'Status Customizados',
    notifications: 'Notificações',
    images: 'Images',
    showInactive: 'Mostrar inativo',
    responsible: 'Responsável',
    comment: 'Comente',
    copyFrom: 'Copiar de',
    copySurveys: 'Copiar pesquisas',
    copyStyling: 'Copiar personalização do cliente',
    createdAt: 'Criada',
    updatedAt: 'Atualizado',
  },
  userTypes: {
    sasi: 'SASI',
    client: 'Cliente',
    clientManager: 'Gerente do Cliente',
    'monitoring-provider': 'Monitoramento',
  },
  mapStatuses: {
    deactivated: 'Desativado',
    active: 'Ativo',
    pending: 'Pendente',
  },
  actions: {
    configure: 'Configurar',
    addRule: 'Adicionar regra',
    addMatchingRule: 'Adicionar associação Formulário - Dados',
    addApp: 'Adicionar App',
    addGroup: 'Adicionar Grupo',
    clear: 'Clara',
    delete_title: 'Excluir %{name}',
    import: 'Importar',
    insert: 'Inserir',
    paste: 'Colar',
    notifyUser: 'Notificar Usuário',
    deactivate: 'Desativado',
    approve: 'Approve',
    discard: 'Discard',
    block: 'Block',
    send: 'Send',
    assignProviders: 'Atribuir Provedor',
    assignProviderTo: 'Atribuir Provedor ao "%{channel}"',
    assignSurveys: 'Atribuir pesquisas',
    assignSurveyTo: 'Atribuir pesquisa to "%{channel}"',
    customize: 'Customize',
    importCsv: 'Importar CSV',
    csvSample: 'Baixar amostra XLS',
    lock: 'Lock',
    unlock: 'Unlock',
    unlockTemporary: 'Temporary Unlock UI',
    dragOrClickPDF: 'Arraste seu arquivo PDF para carregá-lo, ou clique para selecionar',
    showInactive: 'Mostrar inativos',
    settings: 'Configurações',
  },
  placeholders: {
    search: 'Buscar',
  },
  roles: {
    sasi: {
      admin: 'Admin',
      root: 'Superusuário',
      clientManager: 'Gerente do Cliente',
      guest: 'Convidado',
      broadcastOperator: 'Operador de notícias',
    },
    'monitoring-provider': {
      admin: 'Admin',
      operator: 'Operador',
    },
    client: {
      admin: 'Gerente do Cliente',
      manager: 'Gerente do Local',
      approver: 'Aprovador de Perfis Móveis',
    },
    map: {
      worker: 'Site Worker',
      manager: 'Gerente do Local',
    },
  },
  filters: {
    userType: 'User Type',
    from: 'Data Inicial',
    until: 'Data Final',
    scale: 'Escala',
  },
  table: {
    rowsPerPage: 'Linhas por página:',
    displayedRows: '%{from}-%{to} de',
    displayedRowsTooMany: 'mais que %{to}',
  },
  tabs: {
    registration: 'Registro',
    projects: 'Projetos',
    summary: 'Geral',
    results: 'Resultados',
    preview: 'Pré-estréia',
    surveys: 'Pesquisas',
    users: 'Usuários',
    password: 'Senha',
    properties: 'Properties',
    details: 'Details',
    sites: 'Locais',
    maps: 'MAPs',
    client_manager: 'Gerente do Cliente',
    site_manager: 'Gerente do Local',
    team_manager: 'Gerente do Time',
    manager: 'Manager',
    apps: 'Apps',
    channels: 'Canais',
    channelsGroups: 'Canais / Apps',
    sitesGroups: 'Locais / Apps',
    providers: 'Órgão de Monitoramento',
    providersAssignments: 'Atribuições de agência',
    subChannels: 'Sub Canais',
    example: 'Exemplo',
    defaultStatuses: 'Status Padrão',
    visual: 'Visual',
    customize: 'Customizar',
    apiTokens: 'Tokens de API',
    credentials: 'Credenciais',
    licenses: 'Comercial',
  },
  titles: {
    id: 'Id',
    channels: 'Canais',
    lgpd: 'LGPD',
    general: 'Geral',
    terms: 'Termos',
    clientTerms: 'Termos do Cliente',
    visibility: 'Visibilidade',
    order: 'Ordenar',
    delete: 'Deletar',
    empty: 'Empty',
    registrationData: 'Dados cadastrais',
    registrationCodes: 'Códigos de registro',
    registrationHandlers: 'Manipuladores de registro',
    registrationForms: 'Forma de registro',
    createCode: 'Criar Código',
    removeFieldTitle: 'Remover campo?',
    removeFieldContent: 'Deseja remover o campo "%{name}"?',
    insertChannel: 'Inserir canais',
    insertApp: 'Inserir App',
    cleanBuffer: 'Limpar',
    reorderChannels: 'Reordenar',
    reorderFields: 'Reordenar campos',
    search: 'Pesquisa',
    unprocesedAlerts: 'Alertas não atribuídos',
    banner: 'Banner',
    unlockMode: 'Unlock Mode',
    unlockDescription: 'You can temporary unlock UI for editing or globaly unlock client, \n please choose option',
    baseProps: 'Base Properties',
    buttonProps: 'Button Properties',
    preview: 'Pré-visualização',
    testBroadcast: 'Mensagem de teste',
    structure: 'Estrutura',
    style: 'Estilo',
    // general: 'Geral',
    surveyMessageDefaultText: 'Você tem um novo resultado de pesquisa!',
    exportCSV: 'Exportar CSV',
    exportTSV: 'Exportar TSV',
    generateToken: 'Gerar Token',
    generateTokenV2: 'Gerar Token v2',
    system: 'Sistema',
    blacklist: 'Lista Negra',
    apps: 'Apps',
    log: 'Log',
    anonymous: 'Anônimo',
    reports: 'Relatórios',
    config: 'Configuração',
    token: 'Token %{data}',
    create: 'Criar %{resource}',
    edit: 'Edit %{resource}',
    runCommand: 'Run Command',
    valueError: '<Error>',
    close: 'Close',
    sync: 'Sync',
    alerts: 'Alertas',
    profile: 'Perfil',
    justUpdated: 'Atualizado',
    loginAs: 'Login como',
    login: 'Login',
    logout: 'Sair',
    remove: 'Apagar',
    forgotPassword: 'Esqueceu sua senha?',
    uploadCsvDropzone: 'Arraste aqui o seu arquivo CSV para carregar ou clique para selecionar',
    uploadBanner: 'Arraste aqui o seu arquivo bandeira para carregar ou clique para selecionar',
    dateFrom: 'From',
    dateUntil: 'Until',
    yes: 'Sim',
    logoText: 'comunicação customizável',
    attachments: 'Anexos',
    addComment: 'Adicionar comentário',
    statusChanged: 'status alterado para %{status}',
    commentAdded: 'comentar: %{comments}',
    notificationSent: 'notificação enviada: %{comments}',
    customMessage: 'Mensagem Customizada',
    nearSite: 'Perímetro - ',
    okLabel: 'OK',
    clearLabel: 'Limpar',
    cancelLabel: 'Cancelar',
    todayLabel: 'Hoje',
    pdfFileLabel: 'Carregue um PDF',
    admin: 'Admin',
    emailVerification: 'Verifique seu email',
    phoneVerification: 'Verifique seu número de telefone',
  },
  notifications: {
    mapApproved: 'Mobile Panel has been approved',
    mapDeactivated: 'Mobile Panel has been deactivated',
    mapDiscarded: 'Mobile Panel has been discarded',
    syncCompleted: 'Sync completed',
    mapRoleUpdated: 'Map role updated',
    loginAsSuccess: 'Login Successfully',
    forgotSent: 'Uma mensagem foi enviada para seu email, por favor, cheque sua caixa de entrada!',
    iosNotReady: 'Not yet published',
    uploadCsvComplete: 'CSV Upload completed. Please wait while file is processed',
    uploadCsvFailed: 'CSV Upload failed. Invalid csv format',
    uploadCsvValid: 'CSV válido. Preview disponível na aba "Escolhas"',
    uploadCsvInvalid: 'CSV inválido. Permitido somente 2 a 3 colunas',
    statusUpdated: 'O Status foi atualizado',
    surveySuccess: 'A pesquisa foi enviada',
    channelInserted: 'Canal inserido',
    appInserted: 'App inserido',
    appPublished: 'App publicado',
    channelCopied: 'Canal %{name} copiado',
    appCopied: 'App %{name} copiado',
    profileUpdated: 'Seu perfil foi atualizado com sucesso!',
  },
  themes: {
    grey: 'Cinzento',
    blue: 'Azul',
  },
  templates: {
    alert: 'Monitoramento Notificação',
    mapRegister: 'MAP Registrado',
    passwordForgot: 'Esqueceu sua senha',
    userRegister: 'Usuário registrado',
    noDataMessageTemplate: 'Ainda não existem templates criados.',
    descriptionTemplates: 'Encontre modelos prontos de aplicativos que melhor se encaixem em seu projeto.',
    useButton: 'Utilizar',
    optionsButton: 'Configurações de App',
    channels: 'Canais',
    copyLink: 'Copiar Link',
    delete: 'Deletar',
    edit: 'Editar',
    client: 'Cliente',
    public: 'Público',
    recent: 'Recentes',
    older: 'Antigos',
    duplicate: 'Duplicar',
    copyChannel: 'Copiar',
    pasteChannel: 'Colar',
    paste: 'Colar Canal',
    notify: 'Link copiado com sucesso!',
    notifyChannel: 'Canal copiado com sucesso',
    create: 'Novo Template',
    name: 'Nome do App:',
    namePlaceholder: 'Insira o nome deste app.',
    description: 'Descrição do App:',
    descriptionPlaceholder: 'Insira a descrição deste app',
    tags: 'TAG (separado por vírgula)',
    tagsPlaceholder: 'Adicione tags para facilitar a busca pelo template',
    visibility: 'Visível para:',
    visibility_client: 'Cliente',
    visibility_public: 'Público',
    createFor: 'Criado por:',
  },
  validation: {
    passwordConfirm: 'As senhas não são iguais',
    password: {
      tooShort: 'Senha muito curta: mínimo 8 letras',
      tooLong: 'Senha muito longa: máximo 64 letras',
      atLeastOneUppercase: 'Senha inválida: é necessário pelo menos uma letra maiúscula',
      atLeastOneLowercase: 'Senha inválida: é necessário pelo menos uma letra minúscula',
      atLeastOneNumber: 'Senha inválida: é necessário pelo menos um número',
    },
    moreThan: 'O valor deve ser maior que %{source}',
    lessThan: 'O valor deve ser menor que %{source}',
    phoneNumber: 'Número de telefone inválido',
    noValidEmail: 'Você precisa de um email válido para usar esse método.',
    noValidPhoneNumber: 'Você precisa de um número de telefone válido para usar esse método',
    startDateAfterEnd: 'Data de início não pode ser após a data de término!',
    endDateBeforeStart: 'Data de término não pode ser antes da data inicial!',
  },
  messageType: {
    alert: 'Alerta',
    message: 'Mensagem',
  },
  faceRecognitionType: {
    register: 'Register',
    search: 'Search',
  },
  faceRecognitionForm: {
    before: 'Before',
    after: 'After',
  },
  faceRecognitionCamera: {
    front: 'Frontal',
    back: 'Traseira',
  },
  adminAlertStatus: {
    assigned: 'Atribuída',
    pending: 'Pendente',
    notAssigned: 'Não atribuído',
  },
  channelTypes: {
    alert: 'Alerta',
    survey: 'Pesquisa',
    bubble: 'Bubble',
    url: 'URL',
    broadcast: 'Broadcast',
    intercom: 'Intercom',
    pdf: 'PDF',
    group: 'Canal de Grupo',
    phone: 'Telefone',
    whatsapp: 'WhatsApp',
    faceRecognition: 'Face Recognition',
  },
  intercomModes: {
    messenger: 'Messenger',
    web: 'Web',
  },
  providerTypes: {
    standard2: 'Monitoramento Padrão 2',
    standard3: 'sistema.sasi.com.br',
    bubble: 'Monitoramento Bubble',
    email: 'Email',
    telegram: 'Telegram',
    webhook: 'Web Hook',
    api: 'Api',
  },
  handlerModes: {
    client: 'Cliente',
    app: 'App',
    site: 'Local',
  },
  chart_headers: {
    date: 'Data',
    month: 'Mês',
    week: 'Semana',
    map_register: 'Usuários Registrados',
    map_register_total: 'Total de usuários registrados',
    alert: 'Mensagens',
    alert_total: 'Total de Mensagens',
    map_auth: 'Atividade',
  },
  dates: {
    year: 'Ano',
    month: 'Mês',
    date: 'Dia',
    week: 'Semana',
    thisMonth: 'Este mês',
    lastMonth: 'Mês passado',
    thisYear: 'Este ano',
    lastYear: 'Ano passado',
  },
  help: {
    whatIsMfa:
      'Após selecionar uma opção, nas próximas vezes que tentar entrar no SASI Admin você receberá um código de acordo com a opção selecionada, esse código será utilizado para que você entre na sua conta de uma forma mais segura.',
  },
  httpError: {
    authentication: {
      MISSING_USERNAME_OR_PASSWORD: `Nome de usuário e/ou senha não foram preenchidos! 
      (error: %{requestId})`,
      INVALID_USERNAME_OR_PASSWORD: `Nome de usuário e/ou senha inválidos! 
      (error: %{requestId})`,
      INVALID_AUTHORIZATION_HEADER: `Usuário não autenticado! 
      (error: %{requestId})`,
      INVALID_USER: `Usuário inválido! 
      (error: %{requestId})`,
      NO_MFA_METHOD_FOR_USER: `Método de MFA não foi configurado para esse usuário ainda! 
      (error: %{requestId})`,
      MFA_INVALID_CODE: `Código Inválido!
      (error: %{requestId})`,
      JWT_EXPIRED_PASSWORD_RESET: `Tempo expirado! Por favor, requisite a mudança de senha novamente!
      (error: %{requestId})`,
      JWT_EXPIRED_PASSWORD_RESET_NO_REQUEST: `Tempo expirado! Por favor, requisite a mudança de senha novamente!`,
      CLIENT_LOCKED: `Não foi possível realizar esta ação porque o cliente está locked!
      (error: %{requestId})`,
    },
    INTERNAL_ERROR: `Algo deu errado! Por favor, tente novamente mais tarde!
     (error: %{requestId})`,
  },
  welcomeMessages: {
    mfa: {
      title: 'Bem vindo(a) ao SASI!',
      text: 'Para maior segurança de sua conta e acesso a funções administrativas, é necessária autenticação multi-fatores. Vá ao seu perfil para configurá-la.',
      close: 'Fechar',
    },
  },
  authentication: {
    logoutConfirm: 'Tem certeza que deseja sair?',
    loginRedirectConfirm: 'Tem certeza que deseja voltar para a tela de login?',
    goToLogin: 'Voltar para a tela de login',
    goToLoginDescription: 'Ao voltar para a tela de login você não será deslogado, mas poderá entrar com outra conta!',
    logout: 'Sair',
    forgotPassword:
      'Insira seu nome de usuário cadastrado para receber uma mensagem via email ou telefone com as instruções necessárias para redefinir sua senha.',
  },
  ra: {
    page: {
      empty: 'Ainda não há registros!',
      invite: 'Crie um novo recurso no botão abaixo:',
    },
  },
  formErrors: {
    required: 'Campo obrigatório',
    email: 'Email inválido',
  },
  cancel: 'Cancelar',
  disclaimer:
    'Aviso! Os serviços do SASI não substituem a necessidade de fazer uma chamada a 190 ou 193 no caso de uma emergência. Serviços de resposta de emergência só podem ser acessados por de uma chamada direta ao 190 ou 193',
  appWelcome:
    'Bem-vindo ao SASI, nosso novo sistema de alertas.Para começar a usar o sistema, baixe o aplicativo SASI no link a baixo',
  appPasswordRecoverySuccess: 'Sua senha foi redefinida! Você já pode voltar a usar o aplicativo SASI normalmente',
  passwordRecoveryWelcome: 'Insira sua nova senha',
  passwordRecoveryError: 'Houve um erro ao redefinir a senha',
  passwordRecoverySuccess: 'Senha redefinida!',
  passwordLengthRequirement: 'Senha deve ter entre 8 e 64 caracteres e não pode ser vazia',
  passwordSpaceRequirement: 'A senha não deve ter espaços consecutivos',
  missingSiteDataOnCSVUploadError: 'Não foi possível salvar o local pois alguns campos estão faltando: %{fields}',
  couldNotLoadData: 'Não foi possível carregar os dados corretamente! Por favor, tente mais tarde.',
  unauthorized: 'Usuário/senha inválido',
  confirm: 'Confirmar',
  propertyVerificationDescription:
    'Um código de confirmação foi enviado para seu %{platform}! Entre com o código abaixo para continuar.',
  mfaCodeDescription:
    'Um código de confirmação foi enviado para %{mfaValue}! Entre com o código abaixo para continuar.',
  mfaCodeWillExpire: 'Seu código irá expirar em:',
  mfaCodeSending: 'Seu código está sendo enviado, aguarde alguns instantes...',
  mfaCodeMistake: 'Código inválido',
  mfaSetupDescriptionNoAvailable:
    'Para sua melhor segurança, é necessário que você configure a Autenticação de Vários Fatores (MFA). Para isso é necessário que você escolha uma das opções abaixo!',
  mfaInOrderToUse:
    'Para poder usar a opção selecionada, você precisa primeiro adicionar seu %{method} e validá-lo! Por favor, para continuar preencha o valor abaixo:',
  errorSendingMfaCode: 'Algo errado aconteceu ao tentar enviar o seu código, tente novamente mais tarde!',
  errorCode: 'Código de Erro',
  notSignedIn: 'Você ainda não está logado',
  expiredSession: 'Sua sessão expirou, faça login e tente novamente!',
  didntReceiveCode: 'Não recebeu o código? Envie novamente apertando no botão abaixo:',
  requestNewCode: 'Re-enviar código',
  clickHereToVerify: 'clique aqui para verificar!',
  emailNotVerified: 'Seu email ainda não foi verificado,',
  phoneNotVerified: 'Seu telefone ainda não foi verificado,',
};
