import React from 'react';
import { makeStyles } from '@material-ui/core';

import { usePanelStyles, ChannelTypeTextField } from '../ChannelPanels.model';
import { TextFormField } from 'components/designSystem/inputs/TextField';
import { LabeledSwitchField } from 'components/designSystem/LabeledSwitch';
import { DropdownFormField } from 'components/designSystem/Dropdown';
import { useTranslate } from 'ra-core';

const useStyles = makeStyles((theme) => ({
  container: {
    //fazer a margem ainda do sobre o canal e configurações
  },
  wrapper: {
    display: 'flex',
    //border: '1px solid red',
    border: '1px solid',
    borderColor: theme.palette.divider,
    borderLeft: 'none',
    borderRight: 'none',
    borderTop: 'none',
    marginBottom: theme.spacing(2.0),
    marginTop: theme.spacing(1.5),
  },
  configContainer: {
    marginRight: theme.spacing(10),
    //border: '1px solid blue',
    marginLeft: theme.spacing(0),
    marginBottom: theme.spacing(2.5),
  },
  finishButtonsContainer: {
    flex: '1',
  },
  finishOptionsContainer: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  textFields: {
    marginBottom: theme.spacing(1),
    marginTop: 0,
  },
  disclamer: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  optionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: theme.spacing(2),
  },
  subtext: {
    fontSize: theme.spacing(1.5),
    lineHeight: theme.spacing(0.2),
    alignItems: 'center',
    fontWeight: 500,

    marginTop: theme.spacing(1),
    color: theme.palette.text.tertiary,
  },
  setPrimaryContainer: {
    justifyContent: 'flex-end',
    position: 'relative',
    left: theme.spacing(1) * -1,
    marginBottom: theme.spacing(0.5),

    '& > div': {
      color: theme.palette.text.tertiary,
    },
  },
}));

export const faceRecognitionTypes = [
  {
    id: 'register',
    name: 'faceRecognitionType.register',
  },
  {
    id: 'search',
    name: 'faceRecognitionType.search',
  },
];

export const faceRecognitionForms = [
  {
    id: 'before',
    name: 'faceRecognitionForm.before',
  },
  {
    id: 'after',
    name: 'faceRecognitionForm.after',
  },
];

export const faceRecognitionCameraType = [
  {
    id: 'front',
    name: 'faceRecognitionCamera.front',
  },
  {
    id: 'back',
    name: 'faceRecognitionCamera.back',
  },
];

export const FaceRecognitionGeneralPanel = ({ parentChannels, channel }) => {
  const t = useTranslate();
  const classes = useStyles();
  const panelClasses = usePanelStyles();
//  const form = useForm();

  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        <div className={classes.configContainer}>
          <h2 className={panelClasses.sectionTitle}>{t('resources.channels.fields.config.about')}</h2>
          <div className={classes.finishOptionsContainer}>
            <div className={classes.optionsContainer}>
              <TextFormField
                className={classes.textFields}
                name="id"
                disabled
                size="full"
                full
                label={t('resources.channels.fields.config.channelId')}
                helperText={t('resources.channels.fields.config.helpertext')}
              />
              <DropdownFormField
                name="config.parentId"
                full
                items={(parentChannels || []).map((c) => ({ id: c.id, name: c.name }))}
                label={t('resources.channels.fields.config.parentId')}
                placeholder="Selecionar"
                helperText={t('resources.channels.fields.config.helpertext')}
                allowEmpty
              />
              <DropdownFormField
                name="config.faceRecognition.type"
                full
                items={faceRecognitionTypes}
                label={t('resources.channels.fields.config.faceRecognitionType')}
                helperText={t('resources.channels.fields.config.helpertext')}
                translate
              />
              <DropdownFormField
                name="config.faceRecognition.camera"
                full
                items={faceRecognitionCameraType}
                label={t('resources.channels.fields.config.faceRecognitionCamera')}
                helperText={t('resources.channels.fields.config.helpertext')}
                translate
              />
            </div>

            <div className={classes.optionsContainer}>
              <ChannelTypeTextField
                className={classes.textFields}
                name="channelType"
                disabled
                full
                size="full"
                label={t('resources.channels.fields.config.channelType')}
                helperText={t('resources.channels.fields.config.helpertext')}
              />
              <TextFormField
                className={classes.textFields}
                name="tag"
                size="full"
                full
                label="TAG"
                helperText={t('resources.channels.fields.config.helpertext')}
                placeholder={t('resources.channels.fields.placeholders.insertTag')}
              />
              <DropdownFormField
                name="config.faceRecognition.form"
                size="full"
                full
                items={faceRecognitionForms}
                label={t('resources.channels.fields.config.faceRecognitionForm')}
                helperText={t('resources.channels.fields.config.helpertext')}
                translate
              />
            </div>
          </div>
        </div>
        <div className={classes.configContainer}>
          <h2 className={panelClasses.sectionTitle}>{t('resources.channels.fields.config.settings')}</h2>
          <LabeledSwitchField
            reverse
            className={classes.setPrimaryContainer}
            name="primary"
            label={t('resources.channels.fields.config.primary')}
            caption={t('resources.channels.fields.placeholders.primary')}
          />

          <LabeledSwitchField
            reverse
            className={classes.setPrimaryContainer}
            name="config.anonymous"
            label={t('resources.channels.fields.config.anonymous')}
            caption={t('resources.channels.fields.placeholders.anonymous')}
          />
          <LabeledSwitchField
            reverse
            className={classes.setPrimaryContainer}
            name="config.allowGps"
            label={t('resources.channels.fields.config.allowGps')}
            caption={t('resources.channels.fields.placeholders.allowGps')}
          />
          {/* {channel.config?.allowGps && (
            <LabeledSwitchField
              reverse
              className={classes.setPrimaryContainer}
              name="config.allowGeocoding"
              label="Geocodificação GPS"
            />
          )} */}
          <LabeledSwitchField
            reverse
            className={classes.setPrimaryContainer}
            name="config.notifyManager"
            label={t('resources.channels.fields.config.notifyManager')}
            caption={t('resources.channels.fields.placeholders.notifyManager')}
          />
          <LabeledSwitchField
            reverse
            className={classes.setPrimaryContainer}
            name="config.siteManagerOnly"
            caption={t('resources.channels.fields.placeholders.siteManagerOnly')}
            label={t('resources.channels.fields.config.siteManagerOnly')}
          />
          {/*<h5 className={classes.molder}>Este canal será visível somente para o gerente do cliente.</h5>*/}
        </div>
      </div>

      <div className={classes.wrapper}>
        <div className={classes.configContainer}>
          <h2 className={panelClasses.sectionTitle}>{t('resources.channels.fields.config.finallyAlert')}</h2>
          <h3 className={classes.subtext}>{t('resources.channels.fields.config.noCommentary')}</h3>
          <div className={classes.finishOptionsContainer}>
            <div className={classes.optionsContainer}>
              <TextFormField
                className={classes.textFields}
                name="config.sendNowTitle"
                size="full"
                full
                label={t('resources.channels.fields.config.sendNowTitle')}
                helperText={t('resources.channels.fields.config.helpertext')}
                placeholder={t('resources.channels.fields.placeholders.buttons')}
              />
              <h3 size="full" className={classes.subtext}>
                {t('resources.channels.fields.config.commentary')}
              </h3>
              <TextFormField
                className={classes.textFields}
                name="config.sendOtherTitle"
                size="full"
                full
                label={t('resources.channels.fields.config.sendNowTitle')}
                helperText={t('resources.channels.fields.config.helpertext')}
                placeholder={t('resources.channels.fields.placeholders.buttons')}
              />
            </div>
            <div>
              <TextFormField
                className={classes.textFields}
                name="config.sendNowText"
                size="full"
                full
                label={t('resources.channels.fields.config.sendNowText')}
                helperText={t('resources.channels.fields.config.helpertext')}
                placeholder={t('resources.channels.fields.placeholders.example')}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
